.document-section {
    height: 76vh;
    width: fit-content;
    overflow: scroll;
    overflow-x: hidden;

    .card-page {
        border-radius: 0;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 5px rgba(0, 0, 0, 0.12);
    }
}

@media (max-width: 1024px) {
    .document-section {
        height: fit-content;
        overflow-x: scroll;
        overflow-y: hidden;
    }
}
.p-button {
    padding: 0.25rem 1.09375rem !important;
}

.pdf-viewer-footer-row {
    .p-button {
        padding: 0.25rem 1.09375rem !important;
    }
}