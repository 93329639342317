.landing-page-container {
    .landingPageDiv {
        display: flex;
        flex-flow: column nowrap;
        width: 80vw;
        box-sizing: border-box;
        margin: 0;
        position: absolute;
        top: 20rem;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        .item-with-label {
            display: contents;
        }

        .bottom-note {
            position: relative;
            bottom: 0.5rem;
            padding-top: 6rem;
        }

        @media (max-width: 550px) {
            top: 30rem;
        }

        @media (min-width: 551px) and (max-width: 767px) {
            top: 25rem;
        }

    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5); // Semi-transparent overlay
        z-index: 9999; // Ensure it's above other content
    }
}