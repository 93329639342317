.dvText{
    size: A4;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif; 
    font-size: 12px; 
    font-style: normal; 
    font-variant: normal; 
    font-weight: 400; 
    line-height: 20px;
    height: 1220px;
    position: relative;
    min-height: 100vh;
}

.btn-right-section {
    .p-checkbox {
        vertical-align: middle !important;
        margin: 0 !important;
    }
}

.bg-color{
    background-color: #CCC !important;
}

.dashboard-app-nav-bar {
    display: flex;
    height: 30px;
    width: 100%;
    align-items: center;
    border-bottom: 1.5px solid #e5e2e2;
    box-shadow: 0px 15px 10px -15px #8f8f8f;
}

.footer{
    width: 98%;
    position:absolute;
    bottom:0;
    .dashboard-footer {
        display: flex;
        background-color: #B42025;
        height: 50px;
        flex-shrink: 0;
        width: 100%;
        flex-flow: unset;
        align-items: center;
        border-bottom: 1.5px solid #e5e2e2;
        box-shadow: 0px 15px 10px -15px #8f8f8f;     
    }
    .footer-text{
        color: #B42025 ;
        font-size: 10px;
    }
}

.disclaimer-bar {
    display: flex;
    background-color: #a5c6ecba;
    border:#2a7ede 1px solid;
    border-radius: 5px;
    font-size: 12px;
    color: #2a7ede;
    flex-shrink: 0;
    flex-flow: unset;
    align-items: center;      
}

.btn-danger {
    background-color: #B42025 !important;
    .p-checkbox-box.p-highlight {
            background-color: #B42025 !important;
    }
}

.btn-ermc {
    background-color: #282870 !important;
    color: white !important;
    
    .p-checkbox-box.p-highlight {
        background-color: #282870 !important;
    }
}

.btn-link-danger {
    color: #B42025 !important;
}

.btn-link-ermc {
    color: #282870 !important;
}

.logoImg{
    width: 50%;
    height: 50%;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.popup-inner {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

    .txt{
        min-width: 300px !important;
    }
}
