.dashboard-audit {
    display: flex;
    justify-content: center;    

    .dashboard-item {
        --item-size: 114px; // Default size, can be overridden inline
        background-color: #AB192D;
        border-radius: var(--item-size);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 25px;
        height: var(--item-size);
        width: var(--item-size);
        text-decoration: none !important;
        position: relative;
        cursor: pointer;

        .icon {
            font-size: calc(var(--item-size) * 0.44);
        }
    }

    .label {
        color: #AB192D;
        font-size: 0.9rem;
        text-align: center;
        font-weight: 500;
        cursor: pointer;
        text-align: center;
        margin-top: 8px;
        text-decoration: none !important;
    }

    a {
        color: #AB192D;
        text-decoration: none !important;
    }
}

@media (max-width: 767px) {
    .dashboard-audit {
        display: block;
    }
}