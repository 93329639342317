.button-option {
    display: flex;
    width: 22vw !important;
    min-width: 200px;
    height: 35px;
    background: #333333;
    color: white;
    border: unset;
    border-color: #333333;
    margin: 0.5rem 0 !important;
    margin-left: 2.2rem;
    align-items: center;
    position: relative;
    justify-content: center;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
}


.sign-in-page {
    display: flex;
    flex-flow: column nowrap;
    width: auto;
    box-sizing: border-box;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    .header-description {
        margin-top: 2rem;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 2;
    }

    .description {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
    }

    .sign-section {
        display: flex;
        align-items: center;
        vertical-align: middle;
    }
}