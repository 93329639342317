.pdf-viewer-page {
    input.p-inputtext {
        padding: 0.25rem;
    }

    .error {
        padding-top: 10px;
        font-size: 12px;
        color: red;
    }
}