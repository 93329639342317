$font-size-small: 12px;
// Assuming .audit-trail wraps your DataTable component
.audit-trail {
    .p-datatable {
        &.p-datatable-sm {
            font-size: 0.8rem;

            .p-datatable-header,
            .p-datatable-footer,
            .p-column-title,
            .p-datatable-thead>tr>th,
            .p-datatable-tbody>tr>td {
                padding: 0.25rem 0.5rem;
            }

            input.p-inputtext {
                padding: 0.25rem;
            }

            .p-paginator .p-paginator-current,
            .p-paginator .p-paginator-pages,
            .p-dropdown .p-dropdown-label {
                padding: 0.25rem 0.5rem;
            }
        }
    }
    
    .filter-label {
        display: flex-end;
        padding-top: 5px !important;
    }

    .total-label {
        font-weight: 400;
        padding-top: 5px;
    }
}

.datatable-audit {
    p {
        font-size: $font-size-small !important;
        padding: 35px;
    }

    .p-paginator {

        &-first,
        &-prev,
        &-next,
        &-last,
        &-pages .p-paginator-page {
            height: 1rem !important;
        }

        & .p-dropdown {
            height: 1.5rem !important;
        }
    }

    .p-component,
    .p-link,
    .p-inputtext {
        font-size: $font-size-small !important;
    }
}